<template>
  <div>
    <v-row>
      <v-col>
        <h2 class="main-color">{{ $t("My Requests") }}</h2>
      </v-col>
      <v-col class="text-center">
        <label class="search" for="inpt_search">
          <input
            id="inpt_search"
            type="text"
            @focus="inputFocus"
            @blur="inputBlur"
            v-model="search"
          />
        </label>

        <span title="Filter">
          <v-menu
            v-model="filterMenu"
            offset-y
            :close-on-content-click="false"
            :nudge-width="300"
            offset-x
          >
            <template v-slot:activator="{ on }">
              <i v-on="on" class="fas fa-filter main-color fa-lg"></i>
            </template>

            <v-list>
              <v-list-item class="select">
                <v-autocomplete
                  v-model="filter.types"
                  :label="$t('Type')"
                  :items="typesItems"
                  item-text="text"
                  item-value="value"
                  @change="hideLabel = true"
                  multiple
                  solo
                ></v-autocomplete>
              </v-list-item>

              <v-list-item class="select">
                <v-autocomplete
                  v-model="filter.reasons"
                  :label="$t('Vacation Reasons')"
                  :items="reasonsItem"
                  @change="hideLabel = true"
                  item-text="name"
                  item-value="id"
                  multiple
                  solo
                ></v-autocomplete>
              </v-list-item>

              <v-list-item class="select">
                <v-autocomplete
                  v-model="filter.permissionReason"
                  :label="$t('Permission Reasons')"
                  :items="permissionReasonsItem"
                  @change="hideLabel = true"
                  item-text="name"
                  item-value="id"
                  multiple
                  solo
                ></v-autocomplete>
              </v-list-item>

              <v-list-item class="select">
                <v-autocomplete
                  v-model="filter.status"
                  :label="$t('Status')"
                  item-text="text"
                  item-value="value"
                  :items="statusItems"
                  @change="hideLabel = true"
                  multiple
                  solo
                ></v-autocomplete>
              </v-list-item>

              <v-card-actions> </v-card-actions>
            </v-list>
          </v-menu>
        </span>
      </v-col>
      <v-col class="text-right">
        <v-btn class="modal-btn-save" @click="openCreateDialog()">{{
          $t("New Request")
        }}</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="15"
          class="elevation-1 level1"
          hide-default-footer
        >
          <template v-slot:item.files_names="{ item }">
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on">
                  {{ item.files_names.slice(0, 10) }}
                </p>
              </template>
              <span>{{ item.files_names }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.days="{ item }">
            <p>{{ item.days }} <span v-if="item.type == 'Vacation'">D</span></p>
          </template>
          <template v-slot:item.status="{ item }">
            <span :class="item.status_class">{{ item.status }}</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon v-if="item.status == 'Pending'" @click="edit(item)"
              >edit</v-icon
            >
            <v-icon v-else disabled>edit</v-icon>
            <v-icon v-if="item.status == 'Pending'" @click="openDiaDeleat(item)"
              >delete</v-icon
            >
            <v-icon v-else disabled>delete</v-icon>
          </template>
        </v-data-table>
        <!-- ======================================== Pagination ======================================  -->
        <div class="text-center pt-2">
          <v-pagination
            v-if="length > 0 && totalItems > 0"
            v-model="page"
            :length="length"
            :circle="circle"
            :page="page"
            :total-visible="totalVisible"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
          <v-snackbar
            v-model="snack"
            :timeout="snackTime"
            :color="snackColor"
            :right="true"
            :top="true"
          >
            <!-- {{ snackText }} -->

            <span class="white--text">{{ snackText }}</span>

            <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
          </v-snackbar>
        </div>
      </v-col>
    </v-row>

    <!-- modal Request [vacation & permissions] -->
    <v-dialog
      v-model="CreateRequestDialog"
      persistent
      max-width="450px"
      scrollable
    >
      <v-card>
        <v-card-text>
          <v-container>
            <!-- tabs-->
            <v-row style="padding: 20px 0">
              <v-radio-group v-model="requestType" row style="width: 100%">
                <v-col cols="12" md="6">
                  <div>
                    <v-radio :label="$t('Vacation')" value="vacation"></v-radio>
                  </div>
                </v-col>
                <v-col cols="12" md="6"
                  ><v-radio
                    :label="$t('permission')"
                    value="permission"
                  ></v-radio
                ></v-col>
              </v-radio-group>
            </v-row>
            <!-- Request Tab -->
            <v-row v-show="requestType == 'vacation'">
              <v-row>
                <v-col>
                  <h3 class="main-color mb-0" style="font-weight: 600">
                    {{ $t("Leave Request") }}
                  </h3>
                </v-col>
                <v-col></v-col>
              </v-row>
              <v-row v-if="showReaminng">
                <v-col>
                  <div class="days">
                    <span class="dayUsed"
                      >{{ days + " / " + duration }} {{ $t("Days Used") }}</span
                    >

                    <span class="dayRemaining"
                      >{{ remaining }} {{ $t("Days Remaining") }}</span
                    >
                  </div>
                </v-col>
              </v-row>
              <v-form v-model="valid" ref="form">
                <v-row style="display: none">
                  <v-text-field solo></v-text-field>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <label for="start">{{ $t("Start Date") }}</label>
                    <v-text-field
                      id="start"
                      v-model="requestObject.startDate"
                      :rules="[
                        startDateOrEqualRule(
                          requestObject.startDate,
                          requestObject.endDate,
                          $t(
                            'Start date should be less than or equal to end date'
                          )
                        ),
                        validationRules.required
                      ]"
                      autocomplete="off"
                      append-icon="calendar_today"
                      solo
                      dense
                      @keydown.prevent
                      @paste.prevent
                      @drop.prevent
                    ></v-text-field>
                  </v-col>
                  <v-col class="pt-0">
                    <label for="end">{{ $t("End Date") }}</label>
                    <v-text-field
                      id="end"
                      v-model="requestObject.endDate"
                      :rules="[
                        startDateOrEqualRule(
                          requestObject.startDate,
                          requestObject.endDate,
                          $t(
                            'Start date should be less than or equal to end date'
                          )
                        ),
                        validationRules.required
                      ]"
                      append-icon="calendar_today"
                      autocomplete="off"
                      solo
                      dense
                      @keydown.prevent
                      @paste.prevent
                      @drop.prevent
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <label for="reasons">{{ $t("Reasons") }} </label>
                    <v-autocomplete
                      :items="reasonsItem"
                      @change="
                        hideLabel = true && onSelect(requestObject.reason_id)
                      "
                      item-text="name"
                      item-value="id"
                      v-model="requestObject.reason_id"
                      chips
                      :rules="[validationRules.required]"
                      solo
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <label for="Notes">{{ $t("Notes") }}</label>

                    <v-textarea
                      rows="3"
                      v-model="requestObject.notes"
                      solo
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <!-- <label style="display: block">Attach File</label>
                  <input type="File" ref="file" @change="readURL()" /> -->

                    <div class="attach-files">
                      <div
                        class="filesname"
                        v-if="
                          requestObject.fileNames != '' &&
                            requestObject.files.length > 0
                        "
                      >
                        <p class="files">{{ requestObject.fileNames }}</p>
                        <div class="clear">
                          <v-icon
                            title="Clear Attachments"
                            class=""
                            @click="clearAttach()"
                            >close</v-icon
                          >
                        </div>
                      </div>
                      <div v-if="editMode">
                        <div
                          v-for="(file, index) in requestObject.allfiles"
                          :key="index"
                          style="margin-bottom: 10px"
                        >
                          <div class="clear">
                            <p
                              class="files"
                              style="display: inline-block; margin-right: 20px"
                            >
                              {{ file.file_name }}
                            </p>
                            <v-icon
                              title="Clear Attachments ml-3"
                              class=""
                              @click="clearSingleAttach(index, file.id)"
                              >close</v-icon
                            >
                          </div>
                        </div>
                      </div>

                      <v-file-input
                        class=""
                        id="myFileBrowser"
                        style="display: none !important"
                        multiple
                        ref="attachment"
                        @change="readFiles"
                      ></v-file-input>

                      <label
                        class="btn-attach-files"
                        @click="openFileInput()"
                        >{{ $t("Attach File(s)") }}</label
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-row>
            <!-- Permission Tab -->
            <v-row v-show="requestType == 'permission'">
              <v-row>
                <v-col cols="12" md="12">
                  <h3 class="main-color mb-0" style="font-weight: 600">
                    {{ $t("Request Permission") }}
                  </h3>
                </v-col>
                <v-col></v-col>
              </v-row>

              <v-form v-model="validtow" ref="formtow">
                <v-row>
                  <v-col cols="12" md="12" class="pt-0">
                    <label for="start">{{ $t("Date") }}</label>

                    <v-text-field
                      id="permission-date"
                      v-model="permissonObject.permmision_date"
                      :rules="[validationRules.required]"
                      autocomplete="off"
                      append-icon="calendar_today"
                      solo
                      dense
                      @keydown.prevent
                      @paste.prevent
                      @drop.prevent
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <label for="start">{{ $t("Time From") }}</label>
                    <vue-timepicker
                      v-model="permissonObject.permmision_from"
                      ref="fromInput"
                      @close="
                        startTimeRule(
                          permissonObject.permmision_from,
                          permissonObject.permmision_to,
                          $t('Start time should be less than end time')
                        )
                      "
                    ></vue-timepicker>
                    <p class="error--text timeError" v-if="timeError">
                      {{ startTimeError }}
                    </p>
                    <p class="error--text timeError" v-if="timeErrorriq">
                      {{ $t("This field is required") }}
                    </p>
                  </v-col>
                  <v-col>
                    <label for="end">{{ $t("Time To") }}</label>
                    <vue-timepicker
                      ref="toInput"
                      v-model="permissonObject.permmision_to"
                      @close="
                        startTimeRule(
                          permissonObject.permmision_from,
                          permissonObject.permmision_to,
                          $t('End time should be greater than end time')
                        )
                      "
                    ></vue-timepicker>

                    <p class="error--text timeError" v-if="timeError">
                      {{ timeError }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <label for="reasons">{{ $t("Reasons") }} </label>
                    <v-autocomplete
                      :items="permissionReasonsItem"
                      @change="hideLabel = true"
                      item-text="name"
                      item-value="id"
                      v-model="permissonObject.permission_reason_id"
                      chips
                      :rules="[validationRules.required]"
                      solo
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <label for="Notes">{{ $t("Notes") }}</label>

                    <v-textarea
                      rows="3"
                      v-model="permissonObject.notes"
                      solo
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <div class="attach-files">
                      <div
                        class="filesname"
                        v-if="
                          permissonObject.fileNames != '' &&
                            permissonObject.files.length > 0
                        "
                      >
                        <p class="files">{{ permissonObject.fileNames }}</p>
                        <div class="clear">
                          <v-icon
                            title="Clear Attachments"
                            class=""
                            @click="clearAttach()"
                            >close</v-icon
                          >
                        </div>
                      </div>

                      <v-file-input
                        class=""
                        id="myFileBrowser"
                        style="display: none !important"
                        multiple
                        ref="attachment"
                        @change="readFiles"
                      ></v-file-input>

                      <label
                        class="btn-attach-files"
                        @click="openFileInput()"
                        >{{ $t("Attach File(s)") }}</label
                      >
                    </div>

                    <div v-if="editMode">
                      <div
                        v-for="(file, index) in permissonObject.allfiles"
                        :key="index"
                        style="margin-bottom: 10px"
                      >
                        <div class="clear">
                          <p
                            class="files"
                            style="display: inline-block; margin-right: 20px"
                          >
                            {{ file.file_name }}
                          </p>
                          <v-icon
                            title="Clear Attachments"
                            class=""
                            @click="clearSingleAttach(index, file.id)"
                            >close</v-icon
                          >
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <!-- @click="close" -->
          <v-btn class="modal-btn-cancel" @click="closeDialog">{{
            $t("Close")
          }}</v-btn>

          <v-btn
            class="modal-btn-save"
            @click.prevent="createRequest"
            :loading="loading"
            :disabled="loading"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete -->
    <v-dialog
      v-model="dialogReset"
      persistent
      max-width="320"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text>{{
          $t("Are you sure you want to Delet the Request ?")
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="dialogReset = false"
            id="noBtn"
            ref="always_focus"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            id="yesBtn"
            @click="deleteItem(requestObject)"
            class="modal-btn-save yesBtn"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { validationMixin } from "../../../mixins/validationMixin";
import "vue2-timepicker/dist/VueTimepicker.css";

import VueTimepicker from "vue2-timepicker";

import axios from "axios";
export default {
  name: "LeaveRequest",
  mixins: [validationMixin],
  components: { VueTimepicker },
  data() {
    return {
      CreateRequestDialog: false,
      valid: false,
      loading: false,
      editMode: false,
      validtow: false,
      requestType: "vacation",
      dialogReset: false,
      showReaminng: false,
      timeErrorriq: false,
      reasonsItem: [],
      permissionReasonsItem: [],
      statusItems: [
        {
          text: this.$i18n.t("Ongoing"),
          value: "ongoing"
        },
        { text: this.$i18n.t("Pending"), value: "pending" },
        { text: this.$i18n.t("Declined"), value: "declined" },
        { text: this.$i18n.t("Approved"), value: "approved" },
        { text: this.$i18n.t("Expired"), value: "expired" },
        { text: this.$i18n.t("Ended"), value: "ended" }
      ],
      typesItems: [
        { text: this.$i18n.t("Vacation"), value: "vacation" },
        { text: this.$i18n.t("Permission"), value: "permission" }
      ],
      headers: [
        {
          text: this.$i18n.t("Request Type"),
          align: "start",
          sortable: false,
          value: "type"
        },
        { text: this.$i18n.t("Reasons"), value: "reason", sortable: false },
        { text: this.$i18n.t("Days/hours"), value: "days", sortable: false },
        {
          text: this.$i18n.t("Start Day/Date"),
          value: "start_date",
          sortable: false
        },
        { text: this.$i18n.t("End Day"), value: "end_date", sortable: false },
        { text: this.$i18n.t("Status"), value: "status", sortable: false },
        {
          text: this.$i18n.t("#Days Remaining"),
          value: "dayes_remaining",
          sortable: false
        },
        {
          text: this.$i18n.t("Attachment"),
          value: "files_names",
          sortable: false
        },
        {
          text: this.$i18n.t("Notes"),
          value: "approve_notes",
          sortable: false,
        },
        { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
      ],
      startTimeError: "",
      items: [],
      requestObject: {
        id: "",
        startDate: "",
        endDate: "",
        reason_id: "",
        notes: "",
        files: [],
        fileNames: "",
        files_ids: []
      },
      permissonObject: {
        id: "",
        permmision_date: "",
        permmision_to: "",
        permmision_from: "",
        permission_reason_id: "",
        notes: "",
        files: [],
        fileNames: "",
        files_ids: []
      },
      timeError: "",
      snack: "",
      snackTime: 3000,
      snackColor: "",
      snackText: "",
      options: {
        itemsPerPage: 15
      },
      filter: {
        types: [],
        status: [],
        reasons: [],
        permissionReason: []
      },
      duration: "",
      days: "",
      remaining: "",
      search: "",
      circle: true,
      page: 1,
      length: "",
      totalItems: "",
      totalVisible: "",
      filterMenu: false
      // editMode: false,
    };
  },
  watch: {
    CreateRequestDialog: {
      handler() {
        this.activateDatePicker();
      }
    },
    // requestType: {
    //   handler() {
    //     if (this.requestType == "permission") {

    //     }
    //   },
    //   deep: true,
    // },
    "$route.query": {
      handler() {
        if (this.$route.query.page !== undefined) {
          this.page = Number(this.$route.query.page);
          this.getData(this.$route.query.page);
        } else {
          this.getData(1);
        }
      },
      deep: true
    },
    page: function() {
      this.$router.push(
        {
          path: "/leaverequest?page=" + this.page
        },
        () => {}
      );
    },

    search: {
      handler() {
        this.page = 1;
        this.getData(this.page);
      },
      deep: true
    },
    filter: {
      handler() {
        this.page = 1;
        this.getData(this.page);
      },
      deep: true
    }
  },

  methods: {
    openDiaDeleat(item) {
      this.dialogReset = true;
      this.requestObject = item;
    },
    deleteItem() {
      this.loading = true;
      axios
        .get(
          this.getApiUrl + "/requestleave/destroy/" + this.requestObject.id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(response => {
          this.loading = false;
          this.dialogReset = false;
          this.snack = true;
          this.snackColor = "green";
          this.snackText = response.data.status.message;
          this.getData(this.page);
        });
    },
    openCreateDialog() {
      this.cleaeData();
      this.CreateRequestDialog = true;
    },
    closeCreateRequestDialog() {
      this.CreateRequestDialog = false;
      this.showReaminng = false;
    },
    edit(item) {
      this.editMode = true;
      if (item.type == "Vacation") {
        this.requestObject = {
          id: item.id,
          startDate: item.start_date,
          endDate: item.end_date,
          reason_id: item.reason_id,
          notes: item.notes,
          allfiles: item.allfiles,
          files: item.files,
          files_ids: item.files_ids,
          fileNames: item.files_names
        };
      } else {
        this.permissonObject = {
          id: item.id,
          permmision_date: item.start_date,
          permmision_to: item.permmision_to,
          permmision_from: item.permmision_from,
          permission_reason_id: item.permission_reason_id,
          notes: item.notes,
          allfiles: item.allfiles,
          files: item.files,
          files_ids: item.files_ids,
          fileNames: item.files_names
        };
        this.requestType = "permission";
      }

      // this.editMode = true;
      this.CreateRequestDialog = true;
    },

    cleaeData() {
      this.requestObject = {
        id: "",
        startDate: "",
        endDate: "",
        reason_id: "",
        notes: "",
        allfiles: [],
        files: [],
        fileNames: "",
        files_ids: []
      };
      this.permissonObject = {
        id: "",
        permmision_date: "",
        permmision_to: "",
        permmision_from: "",
        permission_reason_id: "",
        notes: "",
        allfiles: [],
        files: [],
        fileNames: "",
        files_ids: []
      };
      this.showReaminng = false;
    },
    setPaginationParameters(response) {
      this.length = response.data.data.last_page;
      if (this.length > 5) this.totalVisible = 5;
    },
    getData(page = null) {
      if (page == null) {
        page = this.page;
      }
      axios
        .get(
          this.getApiUrl +
            "/requestleave?search=" +
            this.search +
            "&page=" +
            page,
          {
            params: {
              filter: this.filter
            },
            headers: {
              Authorization: "Bearer " + localStorage.token
              //the token is a variable which holds the token
            }
          }
        )
        .then(response => {
          this.items = response.data.data.data;
          this.setPaginationParameters(response);
          this.totalItems = response.data.data.total;
        });
    },
    createRequest() {
      console.log("creat request");
      var formValid = "";
      if (this.requestType == "permission") {
        formValid = this.$refs.formtow.validate();
      } else {
        formValid = this.validate();
      }
      const validatevar =
        this.requestType == "vacation" ? this.valid : this.validtow;
      if (validatevar == false) {
        formValid;
      } else {
        this.loading = true;
        var checkInputsTime =
          this.permissonObject.permmision_to == "" ||
          this.permissonObject.permmision_from == ""
            ? true
            : false;
        if (this.requestType == "permission" && checkInputsTime) {
          if (this.permissonObject.permmision_to == "") {
            this.timeError = "This field is required";
          }
          if (this.permissonObject.permmision_from == "") {
            this.timeErrorriq = true;
          }
        } else {
          const formData = new FormData();
          formData.append("type", this.requestType);
          if (this.requestType == "vacation") {
            if (this.requestObject.files.length > 0) {
              this.requestObject.files.forEach(file => {
                formData.append("file[]", file);
              });
            }
            if (this.requestObject.id != "") {
              formData.append("id", this.requestObject.id);
            } else {
              formData.append("id", null);
            }
            formData.append("reason_id", this.requestObject.reason_id);
            formData.append("start_date", this.requestObject.startDate);
            formData.append("end_date", this.requestObject.endDate);
            formData.append("notes", this.requestObject.notes);
          } else if (this.requestType == "permission") {
            if (this.permissonObject.files.length > 0) {
              this.permissonObject.files.forEach(file => {
                formData.append("file[]", file);
              });
            }
            if (this.permissonObject.id != "") {
              formData.append("id", this.permissonObject.id);
            } else {
              formData.append("id", null);
            }

            formData.append(
              "permmision_date",
              this.permissonObject.permmision_date
            );
            formData.append(
              "permission_reason_id",
              this.permissonObject.permission_reason_id
            );
            formData.append(
              "permmision_from",
              this.permissonObject.permmision_from
            );
            formData.append(
              "permmision_to",
              this.permissonObject.permmision_to
            );
            formData.append("notes", this.permissonObject.notes);
          }
          axios
            .post(this.getApiUrl + "/requestleave/store", formData, {
              headers: {
                Authorization: "Bearer " + localStorage.token
              }
            })
            .then(response => {
              this.loading = false;
              if (this.requestType == "vacation") {
                var date1 = moment(this.requestObject.startDate, "DD/MM/YYYY");
                var date2 = moment(this.requestObject.endDate, "DD/MM/YYYY");
                var Difference_In_Time = date2 - date1;
                var Difference_In_Days =
                  Difference_In_Time / (1000 * 3600 * 24);

                if (Difference_In_Days > this.duration) {
                  var calc = Difference_In_Days - this.duration;
                  this.snack = true;
                  this.snackColor = "green";
                  this.snackText =
                    "(" +
                    calc +
                    ") Days of vacation will deducted from vacations other days will be deducted from the salary";
                }
              }
              console.log(response.data);
              // this.$refs.form.resetValidation();
              this.$refs.fromInput.minute = "";
              this.$refs.fromInput.hour = "";
              this.$refs.toInput.minute = "";
              this.$refs.toInput.hour = "";
              this.cleaeData();
              this.closeDialog();
              this.getData(this.page);
            });
        }
      }
    },

    activateDatePicker() {
      // console.log("here")
      var _this = this;
      $(function() {
        $("#start").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function(date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.requestObject.startDate = dateH;
            } else {
              _this.requestObject.startDate = "";
            }
          }
        });
        $("#end").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function(date) {
            if (date[0]) {
              var dateE =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.requestObject.endDate = dateE;
            } else {
              _this.requestObject.endDate = "";
            }
          }
        });

        $("#permission-date").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function(date) {
            console.log("here");
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.permissonObject.permmision_date = dateH;
            } else {
              _this.permissonObject.permmision_date = "";
            }
          }
        });
      });
    },
    closeDialog() {
      this.cleaeData();

      this.closeCreateRequestDialog();
      this.$refs.form.resetValidation();
      this.$refs.formtow.resetValidation();
      this.$refs.fromInput.minute = "";
      this.$refs.fromInput.hour = "";
      this.$refs.toInput.minute = "";
      this.$refs.toInput.hour = "";
    },
    onSelect(reason_id) {
      axios
        .get(
          this.getApiUrl + "/requestleave/getRemainingReasons/" + reason_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
              //the token is a variable which holds the token
            }
          }
        )
        .then(response => {
          this.showReaminng = true;

          this.duration = response.data.data.duration;
          this.days = response.data.data.days;
          this.remaining = response.data.data.remaining;
          // this.requestObject.reason_id = reason_id;
        });
    },
    getReasons() {
      axios
        .get(this.getApiUrl + "/requestleave/getReasons", {
          headers: {
            Authorization: "Bearer " + localStorage.token
            //the token is a variable which holds the token
          }
        })
        .then(response => {
          this.reasonsItem = response.data.data;
        });
    },
    getPermissionsReasons() {
      axios
        .get(this.getApiUrl + "/requestleave/getPermissionsReasons", {
          headers: {
            Authorization: "Bearer " + localStorage.token
            //the token is a variable which holds the token
          }
        })
        .then(response => {
          this.permissionReasonsItem = response.data.data;
        });
    },

    openFileInput() {
      document.getElementById("myFileBrowser").click();
    },
    readFiles(files) {
      var _this = this;

      files.forEach(file => {
        if (this.requestType == "permission") {
          if (_this.permissonObject.fileNames == "") {
            // console.log("permission");
            _this.permissonObject.fileNames = file.name;
          } else {
            _this.permissonObject.fileNames =
              _this.permissonObject.fileNames + ", " + file.name;
          }
          _this.permissonObject.files.push(file);
        } else if (this.requestType == "vacation") {
          if (_this.requestObject.fileNames == "") {
            _this.requestObject.fileNames = file.name;
          } else {
            _this.requestObject.fileNames =
              _this.requestObject.fileNames + ", " + file.name;
          }

          _this.requestObject.files.push(file);
        }
      });
    },
    clearAttach() {
      this.requestObject.files = [];
      this.requestObject.files_ids = [];
      this.requestObject.fileNames = "";
      this.permissonObject.files = [];
      this.permissonObject.files_ids = [];
      this.permissonObject.fileNames = "";
    },
    clearSingleAttach(index, id) {
      if (confirm(this.$i18n.t("File will be deleted permanently"))) {
        axios
          .get(this.getApiUrl + "/requestleave/deleteFile/" + id, {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          })
          .then(response => {
            // console.log(response);
            this.countries = response.data.data;
          });
        if (this.requestType == "permission") {
          this.permissonObject.allfiles.splice(index, 1);
        } else if (this.requestType == "vacation") {
          this.requestObject.allfiles.splice(index, 1);
        }
      }
    },

    startTimeRule(start, end, str) {
      var startTime = moment(start, "HH:mm");
      var endTime = moment(end, "HH:mm");
      startTime >= endTime ? (this.timeError = str) : (this.timeError = "");
      if (this.timeError != "") {
        this.startTimeError = "Start time should be less than end time";
      } else {
        this.startTimeError = "";
      }
      // console.log("error");
    }
  },

  mounted() {
    this.activateDatePicker();
    this.getPermissionsReasons();
    this.getReasons();
    if (this.$route.query.page !== undefined) {
      this.page = Number(this.$route.query.page);
      this.getData(this.page);
    } else {
      this.page = 1;
      this.getData(this.page);
    }

    if (localStorage.is_employee == "false") {
      // console.log("employee check" + localStorage.is_employee);
      this.$router.push(
        {
          path: "/"
        },
        () => {}
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/_variables.scss";
@import "../../../styles/_modals.scss";
.btn-attach-files {
  display: block;
  cursor: pointer;
  padding: 10px;
  background: #7297fe;
  color: #fff;
  border-radius: 15px;
  text-align: center;
  font-weight: 600;
}
.myFileBrowser {
  opacity: 0;
  position: absolute;
  left: 0;
  z-index: 999;
}
.days {
  margin: 0 auto;
  text-align: center;
  .dayUsed,
  .dayRemaining {
    display: inline-block;
    width: 40%;
    background-color: #b9cbff;
    color: #ffffff;
    padding: 0.5rem;
    border-radius: 5px;
  }
}
.status-blue {
  color: #7297ff;
}
.status-yellow {
  color: #ffb400;
}
.status-red {
  color: #ff8972;
}
.status-green {
  color: #5fb870;
}
</style>
